import React from 'react';
import { Link } from 'react-router-dom';

const Banner = ({ title }) => (
  <div className="homeBannerSection">
    <div className="px-md-4 text-center col-12">
      <h1 className="text-uppercase headerBanner fs-48">{title}</h1>
      <div className="col-lg-7 mx-auto">
        <p className="lead mb-4 px-3">Unlock your dream home effortlessly. Tailored listings, personalized service—your ideal space is just a click away. Explore, discover, and embark on your seamless journey with us</p>
      </div>
    </div>
    <div className="row px-5">
      <div className="col d-none d-md-flex"></div>
      <div className="col">
        <div className="mt-5 bannerTitle fs-40">
          Your Trusted <br />Partner In Real <br /> <span className='color-violet'>Estate</span>
        </div>
        <div className="bannerSubtitle mt-3">
          Comprehensive Services for Home Buyers and <br />Sellers Comprehensive Services for Home Buyers and Sellers
        </div>
        <div className='mt-3'>
          <Link to="/about" className="btn btn-primary bg-violet exploreListingButton">
            Explore Our Listing
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default Banner;
