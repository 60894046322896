import { Link, NavLink, useLocation } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import './navbar.css';
import logo from '../assets/logo.svg';

function MainNavbar() {
  const location = useLocation();

  const items = [
    { path: '/', title: 'Home' },
    // { path: '/login', title: 'Login' },
    // { path: '/gallery', title: 'Gallery' },
    { path: '/about', title: 'About' },
  ];

  return (
    <nav className="navbar navbar-expand-lg light-grey shadow-lg p-1 rounded">
      <div className="container-fluid">
        <Link className="navbar-brand p-0" to="/">
          <div class="d-flex align-items-center">
            <img className='m-2' src={logo} alt="" width="36" height="36" />
            <div>
              <span className='pt-3 d-block fw-bolder fs-12 text-golden m-0 p-0'>SATGURU</span>
              <span className='align-top fw-bolder fs-12 text-golden m-0 p-0'>CONSTRUCTION</span>
            </div>
          </div>
        </Link>
        <button className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          aria-controls="navbarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarMenu">
          <ul className="navbar-nav align-items-md-center">
            {
              items.map((item, i) => (
                <li key={i} className={`nav-item px-2 ${location.pathname === item.path ? 'bottom-shadow active' : ''}`}>
                  <NavLink className="nav-link" to={item.path}>{item.title}</NavLink>
                </li>
              ))
            }
            <li className="nav-item px-2">
              <NavLink className="nav-link" to={'/contact'}>
                <Button type="button"
                  className="btn btn-primary bg-violet"
                  onClick={() => { }}
                >
                  Contact
                </Button>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default MainNavbar;
