import React from 'react';
import logo1 from '../../assets/about-services-bg-01.jpg';
import logo2 from '../../assets/about-services-bg-2.jpg';
import logo3 from '../../assets/about-services-bg-3.jpg';

const AboutServicesAndTeam = () => (
  <div className="section-padding row flex align-items-center bg-light-grey">
    <div className="col-12 col-lg-4">
      <img src={logo1} alt="alt" className="rounded w-100 img-fluid" height={'650px'} />
    </div>
    <div className="col-12 col-lg-8 p-md-5">
      <div className='mb-3'>
        <div className='fs-34 fw-bold py-2 '>About Us House Buyers</div>
        <div className='fw-semibold text-body-tertiary'>
          Elevating real estate experiences. Our commitment to excellence, integrity, and personalized service defines us. Unveil a world of possibilities with a team dedicated to your success.
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-md-4 text-center">
          <img src={logo2} height={'270px'} width={'270px'} className='img-fluid rounded mt-3' />
        </div>
        <div className="col-12 col-md-8 px-md-5 d-flex align-items-center">
          <div className=''>
            <div className="pt-2 fs-25 fw-bold text-center text-md-start">Our Services</div>
            <div className="pt-2 fw-semibold text-body-tertiary">
              <b>Residential Sales and Rentals:</b> We offer a diverse portfolio of homes, apartments, and condos for sale or rent, ensuring clients find their dream living space.<br />
              <b>Commercial Real Estate:</b> Our expertise extends to commercial properties, including office spaces, retail units, and industrial facilities, assisting businesses in finding the perfect location to thrive.<br />
              <b>Property Management:</b> We provides comprehensive property management services, handling everything from tenant relations to maintenance, ensuring peace of mind for property owners.<br />
              <b>Investment Opportunities:</b> We identify lucrative investment opportunities, guiding clients through the process of building a robust real estate portfolio.
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 text-center">
          <img src={logo3} height={'270px'} width={'270px'} className='img-fluid rounded mt-3' />
        </div>
        <div className="col-12 col-md-8 px-md-5 d-flex align-items-center">
          <div className=''>
            <div className="pt-2 fs-25 fw-bold text-center text-md-start">Our Team</div>
            <div className="pt-2 fw-semibold text-body-tertiary">
              <b> Expertise: </b>Our team comprises seasoned real estate professionals with in-depth market knowledge.<br />
              <b>  Client-Centric Approach: </b>We prioritize our clients' needs, ensuring a personalized and seamless experience.<br />
              <b>  Market Insight: </b>We stay ahead of market trends, providing clients with strategic insights for informed decision-making.<br />
              <b>  Global Network: </b>With a vast network, we can connect clients with opportunities locally and internationally.<br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutServicesAndTeam;
