import React from 'react';
import SmallAboutCard from './about/smallAboutCard';
import LargeAboutCard from './about/largeAboutCard';
import SmallCardLogo from '../../assets/2150799769.jpg';
import SmallCardLogo1 from '../../assets/2150799797.jpg';
import largeCardLogo from '../../assets/2150799681.jpg';

const About = () => (
  <div className="homeAboutSection bg-light-grey">
    <div className="aboutTitleSection text-center">
      <div className="aboutTextSection">
        <span className="color-violet">ABOUT</span> SATGURU CONSTRUCTION
      </div>
    </div>
    <div className="row aboutMediaSection text-center mt-5">
      <div className="col-12 col-md-4 mb-5 mb-md-0">
        <SmallAboutCard
          image={SmallCardLogo}
          title="Our Legacy"
          content="Crafting Dreams, Shaping Futures. Explore our enduring legacy in real estate excellence and discover the foundation of trust we've built over time."
        />
      </div>
      <div className="col-12 col-md-4 mb-5 mb-md-0">
        <LargeAboutCard
          image={largeCardLogo}
          title="Our Story"
          content="We're Navigating Excellence, Shaping Communities. Learn about our Passion, Commitment, and the Vision Driving Unparalleled Real Estate Experiences."
        />
      </div>
      <div className="col-12 col-md-4">
        <SmallAboutCard
          image={SmallCardLogo1}
          title="Our Team"
          content="Passionate professionals dedicated to delivering exceptional service, expertise, and personalized guidance in every step of your real estate journey."
        />
      </div>
    </div>
  </div>
);

export default About;
