import React, { useRef } from 'react';
import { Carousel } from 'react-bootstrap';
import CustomerTestimonialCard from './customerTestimonialsComponents/customerTestimonialsCards';
import ProfileLogo from '../../assets/profile_logo.jpg';

const CustomerTestimonials = () => {
  const carouselRef = useRef(null);
  const handleSlideLeft = () => {
    carouselRef.current.prev();
  };
  const handleSlideRight = () => {
    carouselRef.current.next();
  };
  const cardContent = [
    [
      {
        logo: 'https://lh3.googleusercontent.com/a-/ALV-UjWpwFiPbEs9I2L1QD23VybCY8NjXD8hRRwkxLylvrvQLtYf',
        name: 'Dr. Dinesh Kumar Verma',
        content:
          'Exceptional real estate service! The team at  सतगुरु कंस्ट्रक्शन demonstrated utmost professionalism, guiding us through every step of the process. Their expertise, reliability, and transparent communication made our home buying/selling experience seamless. I have invested on a plot, a year ago and I got best price in market.',
        rating: 5,
        link: 'https://g.co/kgs/d8hBqiV',
      },
      {
        logo: 'https://lh3.googleusercontent.com/a-/ALV-UjUPivasy1IeJEeRVEN-ShIiq34rb5crI6-uKXJKM7bXj8E',
        name: 'Reena Nagar',
        content:
          'Wanted to say a special thanks for making our building (Dream Home) experience so wonderful. Your work was excellent. You will come highly recommended from us. Thanks again. We really appreciate you and what you have done',
        rating: 5,
        link: 'https://g.co/kgs/R6Gs6i9',
      },
      {
        logo: 'https://lh3.googleusercontent.com/a-/ALV-UjUqTEl1VVk6m1FgZkH22mZqUpxiVuxLXJFfDYZIlj8y6Ao=w75-h75-p-rp-mo-br100',
        name: 'Anurag Galande',
        content:
          'Kailash ji has proved his identity through his work in a very short time. The plot was taken from them last 3 years ago and now construction is being done on it by them only.',
        rating: 5,
        link: 'https://g.co/kgs/cWHgxuH',
      },
    ],
    [{
      logo: 'https://lh3.googleusercontent.com/a/ACg8ocJZONbLDHGIX1tN2fBDkcLQtslQpsJxfOeUmK9Gnvsn=w75-h75-p-rp-mo-br100',
      name: 'Bhavar Kumawat',
      content: 'सतगुरु कंस्ट्रक्शन द्वारा हमने उज्जैन में प्राधिकरण की कॉलोनी त्रिवेणी विहार मैं 3 0 x 5 0 का प्लॉट लिया है सी सेक्टर में और आज की तारीख में वह प्लॉट आवासीय  होने के बावजूद अच्छे प्रॉफिट में है बहुत बहुत धन्यवाद सद्गुरु कंस्ट्रक्शन',
      rating: 5,
      link: 'https://g.co/kgs/7L1RYw8',
    },
    {
      logo: 'https://lh3.googleusercontent.com/a-/ALV-UjVSevzqo9PaNwPVqxZ7Aht1Y-T_GaZbyA4u6mGgr37P6JxO=w75-h75-p-rp-mo-ba3-br100',
      name: 'Nikhil Gothwal',
      content: 'Best quality work at best price and wonderful nature .',
      rating: 5,
      link: 'https://g.co/kgs/yEfNMs2',
    },
    {
      logo: 'https://lh3.googleusercontent.com/a/ACg8ocJnw5QdHABuAlkUTspT4l276pezdF5IUHSYkGpLGmk1=w75-h75-p-rp-mo-br100',
      name: 'Rakesh Chouhan',
      content: 'Best construction , reliable person, Mr. Kailash ji Rathore',
      rating: 5,
      link: 'https://g.co/kgs/VPybhYp',
    },
    ],
  ];
  return (
    <div className="customerTestimonialSection">
      <div className="customerTestimonialTitle fw-bold mb-3 fs-36">
        <span className="color-violet">Customer</span> testimonials
      </div>
      <div className="customerTestimonialNavigator d-md-flex justify-content-between mb-md-5 mb-3">
        <div className=" text-muted fs-7">Genuine voices, real experiences. Discover the satisfaction our customers share. Their stories define our commitment to excellence.</div>
        <div className="d-flex mt-3 mt-md-0 justify-content-around align-items-start me-md-5">
          <div className="me-2">
            <i class="bi bi-arrow-left-circle-fill color-violet fs-2 cursor-pointer" onClick={() => handleSlideLeft()}></i>
          </div>
          <div>
            <i class="bi bi-arrow-right-circle-fill color-violet fs-2 cursor-pointer" onClick={() => handleSlideRight()}></i>
          </div>
        </div>
      </div>
      {/* For web */}
      <div className="d-none d-md-flex">
        <Carousel ref={carouselRef} controls={false} indicators={false}>
          {cardContent.map((items, i) => (
            <Carousel.Item className="row" key={i}>
              <div className="d-flex justify-content-between">
                {items.map((item) => {
                  const { logo, name, content, rating, link } = item;
                  return (
                    <CustomerTestimonialCard logo={logo} name={name} content={content} rating={rating} link={link} />
                  );
                })}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      {/* For Mobile */}
      <div className="d-md-none">
        <Carousel ref={carouselRef} controls={false} indicators={false}>
          {cardContent.flat(1).map((item) => {
            const { logo, name, content, rating, link } = item;
            return (
              <Carousel.Item className="row">
                <CustomerTestimonialCard logo={logo} name={name} content={content} rating={rating} link={link} />;
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div >
    </div >
  );
};

export default CustomerTestimonials;
