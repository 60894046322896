import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Location = () => (
    <div className="container-fluid p-0">
        <Row>
            <Col md={12}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.8528837162835!2d75.8083170750955!3d23.139052079092316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39637393b4fa8153%3A0x9e1795323a3e14ad!2sSatguru%20Construction!5e0!3m2!1sen!2sin!4v1705221337125!5m2!1sen!2sin"
                    width="100%" height="580" style={{ border: 0, display: 'block' }}
                    allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </Col>
        </Row>
    </div>
);

export default Location;
