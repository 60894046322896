import React from 'react';

const HowItWorksCard = ({ logo, title, content }) => (
  <div className="col-md-4 mb-4">
    <div className="howItWorksCardSection h-100 border rounded px-4 py-5 mx-md-3">
      <div className="howItWorksCardLogo d-flex justify-content-center">
        <div className="howItWorksCardLogoBackgroundSection d-flex justify-content-center align-items-center rounded-circle">
          <i className={`text-violet bi bi-${logo} h1 rounded-circle`}></i>
        </div>
      </div>
      <div className="howItWorksCardTitle my-3 text-center aboutCardTitle ">{title}</div>
      <div className="howItWorksCardsContent">{content}</div>
    </div>
  </div>
);

export default HowItWorksCard;
