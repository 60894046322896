import { Helmet } from 'react-helmet';
import Subscribe from '../components/Subscribe/Subscribe';
import Banner from '../components/Home/banner';
import About from '../components/Home/about';
import CustomerTestimonials from '../components/Home/customerTestimonials';
import HowItWorks from '../components/Home/howItWorks';
import Location from '../components/Location/Location';

function Home() {
  const title = 'Satguru Construction & Properties';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-fluid px-0 overflow-hidden">
        <Banner title={title} />
        <About />
        <CustomerTestimonials />
        <HowItWorks />
        <Location />
        <Subscribe />
      </main>
    </>
  );
}

export default Home;
