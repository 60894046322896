import React from 'react';
import AboutBaner from '../components/About/baner';
import AboutDetails from '../components/About/details';
import AboutServicesAndTeam from '../components/About/servicesAndTeam';
import IntroduceTheTeam from '../components/About/introduceTheTeam';
import Subscribe from '../components/Subscribe/Subscribe';
import CustomerTestimonials from '../components/Home/customerTestimonials';

const About = () => (
  <div className="container-fluid px-0 overflow-hidden">
    <AboutBaner />
    <AboutDetails />
    <AboutServicesAndTeam />
    <CustomerTestimonials />
    {/* <IntroduceTheTeam /> */}
    <Subscribe />
  </div>
);

export default About;
