import { Field, Form, Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { emailPattern, phonePattern } from '../../common/constants';
import contactUs from '../../axios/contactUs';

const contactUsSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Name should be less than 30 characters')
    .required('Name is required'),
  email: Yup.string().matches(emailPattern, 'Please enter an valid email').required('Email is required'),
  phone: Yup.string()
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number')
    .matches(phonePattern, 'Please enter a valid phone number')
    .required('Phone is required'),
  description: Yup.string().max(100, ' Too Long!'),
});

const ContactForm = () => {
  // State
  const [description, setDescription] = useState('');
  // Constants
  const hours = [
    'Mon 09:00am - 09:00pm',
    'Tue 09:00am - 09:00pm',
    'Wed 09:00am - 09:00pm',
    'Thu 09:00am - 09:00pm',
    'Fri 09:00am - 09:00pm',
    'Sat 09:00am - 09:00pm',
    'Sun 09:00am - 09:00pm',
  ];
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    description: '',
  };

  // States
  const [loader, setLoader] = useState(false);

  return (
    <>
      <ToastContainer />
      <div className="contactUsFormDivision">
        <div className="">
          <div className="row p-md-5 bg-white rounded">
            <div className="col-12 col-md-6 contactUsDetails bg-white mb-5 ">
              <div className="fs-36 fw-bolder pb-3">
                Contact <span className="color-violet">Us</span>
              </div>
              <div>
                <div className="fw-bold py-1">Better yet, see us in person!</div>
                <div className="py-1 fw-semibold">
                  We love our customer, so feel free to visit during normal business hours
                </div>
              </div>
              <div>
                <div className="fw-bold py-1">Satguru Construction & Properties</div>
                <div className="py-1  fw-semibold">E-11/41, Kshipra Vihar, Panchkoshi Marg, Ujjain (M.P.)</div>
                <div className="py-1  fw-semibold">+919244837399</div>
                <div className="py-1  fw-semibold">info.satguru.constructions@gmail.com</div>
              </div>
              <div>
                <div className="fw-bold py-1">Hours</div>
                {hours.map((hour, index) => (
                  <div className="py-1  fw-semibold" key={`hour${index}${hour}`}>
                    {hour}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 col-lg-6 contactUsForm rounded">
              <div className="contact-us-form-content">
                <div className="contact-us-form-title fw-bold-700 fs-36 mb-2">Drop us a line</div>
                <div className="contact-us-form-subtitle pb-4">
                  Your inquiries, dreams, and real estate aspirations are the heartbeat of our commitment. Let's start
                  the conversation.
                </div>
              </div>
              <div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={contactUsSchema}
                  onSubmit={(values, actions) => {
                    setLoader(true);
                    contactUs(values)
                      .then(() => {
                        setDescription('');
                        toast.success("Thank you for reaching out! We've received your submission and our team will be in touch.");
                        actions.resetForm({
                          values: initialValues,
                        });
                      })
                      .catch(() => {
                        toast.error('Oops! Something went wrong. Please double-check your information or try again after some time.');
                      })
                      .finally(() => {
                        setLoader(false);
                      });
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="">
                        <label className="contact-us-form-label fw-semibold">Name</label>
                      </div>
                      <div className="pb-2">
                        <Field name="name" className="formFields bg-transparent" />
                        {errors.name && touched.name ? <div className="text-danger pb-1">{errors.name}</div> : null}
                      </div>
                      <div className="">
                        <label className="contact-us-form-label fw-semibold">Email</label>
                      </div>
                      <div className="pb-2">
                        <Field name="email" className="formFields bg-transparent" />
                        {errors.email && touched.email ? <div className="text-danger pb-1">{errors.email}</div> : null}
                      </div>
                      <div className="">
                        <label className="contact-us-form-label fw-semibold">Phone</label>
                      </div>
                      <div className="pb-2">
                        <Field name="phone" type="text" className="formFields bg-transparent" />
                        {errors.phone && touched.phone ? <div className="text-danger pb-1">{errors.phone}</div> : null}
                      </div>
                      <div className="">
                        <label className="contact-us-form-label fw-semibold">Description</label>
                      </div>
                      <div>
                        <Field name="description" type="text" className="formFields bg-transparent" />
                        {/* <textarea
                          name="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className="formFields bg-transparent"
                        /> */}
                      </div>
                      {errors.description && touched.description ? (
                        <div className="text-danger pb-1">{errors.description}</div>
                      ) : null}
                      <div className="d-flex justify-content-center justify-content-md-end pt-5">
                        <button
                          type="submit"
                          disabled={loader}
                          className="btn btn-primary bg-violet border-violet submitButton"
                        >
                          {loader ? <Spinner animation="border" size="sm" /> : 'Submit'}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
