import React from 'react';
import ContactBaner from '../components/Contact/banner';
import ContactForm from '../components/Contact/contactForm';
import Location from '../components/Location/Location';
import Subscribe from '../components/Subscribe/Subscribe';

const ContactUs = () => (
  <div className='container-fluid px-0 overflow-hidden'>
    <ContactBaner />
    <ContactForm />
    <Location />
    <Subscribe />
  </div>
);
export default ContactUs;
