import React from 'react';

const AboutBaner = () => (
  <div className="homeBannerSection px-5">
    <div className="row">
      <div className="col col d-none d-md-flex"></div>
      <div className="col">
        <div className="mt-5 bannerTitle fs-40">
          <span className="color-violet">About</span> with Impact
        </div>
        <div className="bannerSubtitle mt-3">
          Discover our story, dedicated to excellence and community impact. We're driven by a mission to create positive change, forging a meaningful path that reflects our values and commitment to you.
        </div>
      </div>
    </div>
  </div>
);

export default AboutBaner;
