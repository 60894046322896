import React from 'react';

const ContactBaner = () => (
  <div className="homeBannerSection px-5">
    <div className="row">
      <div className="col d-none d-md-flex"></div>
      <div className="col">
        <div className="mt-5 bannerTitle fs-40">
          <span className="color-violet">Contact</span> with Impact
        </div>
        <div className="bannerSubtitle mt-3">
          Connect with us. Our dedicated team is ready to assist you. Reach out for expert guidance, personalized solutions, and a seamless real estate experience. Your dream property awaits!
        </div>
      </div>
    </div>
  </div>
);

export default ContactBaner;
