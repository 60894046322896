import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="light-grey p-3">
    <Container className='my-5'>
      <Row className='mb-4'>
        <Col md={4}>
          <h6>About Us</h6>
          <p className='text-secondary fw-semibold'>
            Embrace the journey with us. We are a dedicated team of real estate professionals committed to transforming your property aspirations into reality. Discover the difference with our personalized service and expertise          </p>
        </Col>
        <Col md={2} >
          <h6>Quick Links</h6>
          <ul className="list-unstyled">
            <li className='pb-2'>
              <Link to="/" className='fw-semibold text-secondary'>Home</Link>
            </li>
            {/* <li className='pb-2'>
              <Link to="/gallery" className='fw-semibold text-secondary'>Properties</Link>
            </li> */}
            <li className='pb-2'>
              <Link to='about' className='fw-semibold text-secondary'>About Us</Link>
            </li>
            <li className='pb-2'>
              <Link to="contact" className='fw-semibold text-secondary'>Contact Us</Link>
            </li>
          </ul>
        </Col>
        <Col md={2} className='left'>
          <h6>Social Links</h6>
          <ul className="list-unstyled">
            <li className='pb-2'>
              <a href="https://g.co/kgs/mo2HmQq" target="_blank"><i className="text-violet bi bi-google"></i> <span className='text-secondary fw-semibold'>Google</span></a>
            </li>
            <li className='pb-2'>
              <a href="https://www.facebook.com/profile.php?id=61555414853965" target="_blank"><i className="text-violet bi bi-facebook"></i><span className='text-secondary fw-semibold'> Facebook</span></a>
            </li>
            <li className='pb-2'>
              <a href="https://www.instagram.com/sat_guru_construction_ujjain?igsh=MWV0ZWFwMGk5ZDR6eA==" target="_blank"><i className="text-violet bi bi-instagram"></i> <span className='text-secondary fw-semibold'>Instagram</span></a>
            </li>
            <li className='pb-2'>
              <a href="https://wa.me/message/BZVS5GBJZIU6L1" target="_blank"><i className="text-violet bi bi-whatsapp"></i><span className='text-secondary fw-semibold'> Whatsapp</span></a>
            </li>
          </ul>
        </Col>
        <Col md={4} className='right px-md-4'>
          <h5 className='text-violet fw-bold pb-2'>Satguru Construction & Properties</h5>
          <address className='fw-bold text-secondary'>
            <p><i className="text-violet bi bi-house"></i> E-11/41, Kshipra Vihar, Panchkoshi Marg, Ujjain (M.P.)</p>
            <p><i className="text-violet bi bi-envelope"></i> Email: info.satguru.constructions@gmail.com</p>
            <p><i className="text-violet bi bi-phone"></i> Phone: +91 9244837399</p>
          </address>
        </Col>
      </Row>
      <Row className='bottom'>
        <Col md={6}>
          <p className='my-4 text-secondary'>© 2020 Satguru Construction, All rights Reserved.</p>
        </Col >
        <Col md={6}>
          <p className='my-4 text-secondary'>This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">&nbsp;Privacy Policy</a> and
            <a href="https://policies.google.com/terms">&nbsp;Terms of Service</a> apply.
          </p>
        </Col>
      </Row>

    </Container >
  </footer >
);

export default Footer;
