import React from 'react';

const SmallAboutCard = ({ image, title, content }) => (
  <div className="mt-md-5">
    <div className="rounded">
      <img src={image} alt="" className="rounded img-fluid" height={'230px'} width={'360px'} />
    </div>
    <div className="my-3 aboutCardTitle">{title}</div>
    <div className="aboutGalleryCardContent fw-semibold">{content}</div>
  </div>
);

export default SmallAboutCard;
