import React from 'react';
import logo1 from '../../assets/owner_profile_1.jpg';
import logo2 from '../../assets/2150799797.jpg';

const AboutDetails = () => (
  <div className="section-padding row flex align-items-center bg-light-grey">
    <div className="col-12 col-lg-5 flex align-items-center">
      <div className="fw-bold fs-1 pb-4">
        <span className="color-violet ">KAILASH</span> RATHORE
      </div>
      <div className="fw-semibold text-body-tertiary pb-4 fs-6">
        Our mission is to empower individuals and businesses to find their ideal property, creating lasting value and
        enriching communities. We prioritize transparency, integrity, and client satisfaction in every transaction.
      </div>
      <div className="fw-semibold text-body-tertiary pb-4 fs-6">
        <div className="pb-2 fs-25 fw-bold text-center text-black text-md-start">Core Values</div>
        <b>Excellence:</b> We strive for excellence in every aspect of our business, from customer service to property transactions.<br />
        <b> Integrity:</b> Trust is the foundation of our relationships. We uphold the highest standards of integrity and ethics in all dealings.<br />
        <b> Innovation:</b> Embracing innovation, we leverage cutting-edge technology to streamline processes and enhance the client experience.<br />
        <b> Community Impact:</b> Beyond transactions, we are dedicated to making a positive impact on the communities we serve.
      </div>
    </div>
    <div className="col-12 col-lg-7 d-flex justify-content-around">
      <div className="d-flex flex-wrap align-items-center  col-6">
        <img src={logo1} alt="alt" className="rounded img-fluid" height={'535px'} width={'300px'} />
      </div>
      <div className="col-6  aboutus_content position-relative rounded d-flex align-items-center justify-content-center fw-bold p-4 text-white fs-34">
        <div className="position-absolute text-center">The greatest luxury is being free.</div>
      </div>
    </div>
  </div>
);

export default AboutDetails;
