/* eslint-disable no-plusplus */
import React from 'react';
import { Card } from 'react-bootstrap';
import ProfileLogo from '../../../assets/profile_logo.jpg';

const CustomerTestimonialCard = ({ logo, name, content, rating, link }) => {
  const getStar = (ratingCount) => {
    const ratingElementArray = [];
    for (let i = 1; i <= 5; i++) {
      ratingElementArray.push(i <= ratingCount ? <i className="bi bi-star-fill color-violet me-2 fs-5"></i> : <i className="bi bi-star me-2 fs-5"></i>);
    }
    return ratingElementArray;
  };
  return (
    <div className="col-12 col-lg-4 p-2 customerTestimonialCardDivision">
      <Card className="p-2 testimonialCard h-100">
        <Card.Header className='bg-white border-0'>
          <img src={logo} alt="" className="rounded-circle d-inline" height={'60'} width={'60'}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ProfileLogo;
              return currentTarget;
            }}
          />
          <span className='fs-6 fw-semibold ms-3'>{name}</span>
        </Card.Header>
        <Card.Body>
          <Card.Text className="text-muted fs-7 px-2">
            {content.length > 80 ? <>{content.substring(0, 80)}...</> : content}
          </Card.Text>
        </Card.Body>
        <Card.Footer className='bg-white border-0 mx-2'>
          <div className="customerTestimonialCardRatings">{getStar(rating).map((item) => item)}
            <a className="float-end align-end mt-1" href={link} target="_blank">Read review</a></div>
        </Card.Footer>
      </Card>
    </div >
  );
};

export default CustomerTestimonialCard;
