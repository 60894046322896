import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import contactUs from '../../axios/contactUs';
import './subscribe.css';

const Subscribe = () => {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState({ email: '' });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email.email) return;
    setLoader(true);
    contactUs(email)
      .then(() => {
        setEmail({ email: '' });
        toast.success("Congratulations! You're now subscribed. Get ready for updates and exciting news delivered straight to your inbox");
      })
      .catch(() => {
        toast.error('Oops! Something went wrong. Please double-check your information or try again after some time.');
      })
      .finally(() => {
        setLoader(false);
      });
  };
  return (
    <>
      <ToastContainer />
      <div className=" bg-img">
        <Container className="d-flex align-items-center justify-content-center text-white">
          <Row className="px-4 my-5 text-center">
            <h4 className="display-5 fw-bold">SUBSCRIBE</h4>
            <Col md={12} className="mx-auto">
              <p className="lead mb-4">
                Subscribe today to enjoy a world of privileges, personalized content, and first-class support. Elevate
                your experience and stay ahead with our premium service. Your journey to exceptional service begins
                here!
              </p>
            </Col>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Row className="d-flex align-items-center justify-content-center">
                <Col md={4} className="mt-2">
                  <input
                    type="email"
                    className="form-control p-3"
                    id="email"
                    name="email"
                    value={email.email}
                    placeholder="Enter your email"
                    onChange={(e) => setEmail({ email: e.target.value })}
                  />
                </Col>
                <Col xs="auto">
                  <Button type="submit" disabled={loader} className="btn btn-primary gap-3 p-3 mt-2 bg-violet">
                    Subscribe {loader ? <Spinner animation="border" size="sm" /> : <i className="bi bi-bell"></i>}
                  </Button>
                </Col>
              </Row>
            </form>
            <Col md={12} className="mx-auto mt-4">
              <p className="lead mb-4">"Subscribe and Thrive! Access Exclusive Content and Offers Instantly."</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Subscribe;
