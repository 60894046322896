import React from 'react';
import HowItWorksCard from './about/howItWorks/howItWorkCard';

const HowItWorks = () => {
  const cardContent = [
    {
      logo: 'house-fill',
      title: 'Explore Listings',
      content:
        'Discover a diverse range of homes tailored to your preferences.',
    },
    {
      logo: 'person-fill',
      title: 'Connect with Agents',
      content:
        'Engage with experienced agents for personalized guidance and assistance.',
    },
    {
      logo: 'key-fill',
      title: 'Secure Your Property',
      content:
        'Streamlined processes make offers, negotiations, and closing hassle-free. Welcome to your new home!',
    },
  ];
  return (
    <div className="howItWorksSection bg-light-grey">
      <div className="howItWorksTitle text-center fw-bold fs-36">How It <span className="color-violet">Work</span></div>
      <div className=" mx-md-auto mb-5 mt-2 col col-md-6 text-center justify-content-center">
        Explore listings tailored to your taste, connect with expert agents for personalized guidance, and seamlessly secure your dream home. Simplifying the home-buying journey      </div>
      <div className="howItWorksCards row">
        {cardContent.map((item) => {
          const { logo, title, content } = item;
          return <HowItWorksCard logo={logo} title={title} content={content} />;
        })}
      </div>
    </div>
  );
};

export default HowItWorks;
